import React, {useState} from 'react';
import './style.css';
import {recipientTypesEnum} from "../../api/model/Enums";
import {Checkbox, Dropdown, Table} from "semantic-ui-react";
import InputDropdownCheckbox from "../../components/inputs/InputDropdownCheckbox";
import {POOLING_EMAIL} from "../../api/globalConstants";

const EditRow = ({options, initialRow, actions}) => {
    const [row, setRow] = useState({...initialRow});

    const handleChange = (e, {value, name}) => {
        let newRow = {...row};
        newRow[name] = value;
        name === 'recipientType' && autocompleteEmail(newRow, value);
        setRow(newRow);
    }

    const autocompleteEmail = (row, recipientType) => {
        switch (recipientType) {
            case recipientTypesEnum.EMAIL:
            case recipientTypesEnum.USER_EMAIL:
                row['email'] = '';
                row['roles'] = [];
                return;
            case recipientTypesEnum.POOLING:
                row['email'] = POOLING_EMAIL;
                row['roles'] = [];
                return;
            default:
                row['email'] = '';
                return;
        }
    }

    function sortData(x, y) {
        if (x.text < y.text) return -1;
        if (x.text > y.text) return 1;
        return 0;
    }

    return <Table.Row
        className={'notifications__new-row'}
    >
        <Table.Cell className='notifications__table-cell'>
            <Dropdown
                className="notifications__input"
                noResultsMessage={'Результатов не найдено'.t}
                selection
                search
                name='notificationType'
                value={row['notificationType']}
                onChange={handleChange}
                placeholder={"Найдите наименование уведомления".t}
                fluid
                closeOnChange
                options={options.types ? options.types.sort(sortData) : []}
                selectOnBlur={false}
            />
        </Table.Cell>
        <Table.Cell className='notifications__table-cell'>
            <Dropdown
                className="notifications__input"
                noResultsMessage={'Результатов не найдено'.t}
                selection
                name='recipientType'
                value={row['recipientType']}
                onChange={handleChange}
                placeholder={"Найдите тип адресата".t}
                fluid
                closeOnChange
                options={options.recipients || []}
                selectOnBlur={false}
            />
        </Table.Cell>
        <Table.Cell className='notifications__table-cell'>
            <input
                type="email"
                className="notifications__input"
                name='email'
                value={row['recipientType'] === recipientTypesEnum.USER_EMAIL ? "Email пользователя" : row['email']}
                onChange={(e) => handleChange(e, {value: e.target.value, name: 'email'})}
                placeholder={"Введите Email".t}
                disabled={row['recipientType'] !== recipientTypesEnum.EMAIL}
            />
        </Table.Cell>
        <Table.Cell textAlign='center' className='notifications__table-cell'>
            <InputDropdownCheckbox
                multiple
                className="notifications__input"
                noResultsMessage={'Результатов не найдено'.t}
                search
                name='roles'
                value={row['roles'] && row['roles'].map(r => r.userRoleId)}
                onChange={(e) => handleChange(e, {
                    value: e.map(e => {
                        let role = options.roles && options.roles.find(r => r.key === e);
                        return {userRoleId: role.key, name: role.text}
                    }),
                    name: 'roles'
                })}
                allLabel={'Все роли'}
                placeholder={"Найдите роль".t}
                options={options.roles || []}
                disabled={!row['recipientType'] || row['recipientType'] !== recipientTypesEnum.ROLE}
            />
        </Table.Cell>
        <Table.Cell textAlign='center'>
            <Checkbox
                style={{cursor: 'pointer'}}
                checked={row['considerCompany']}
                onChange={(e, {checked}) => handleChange(e, {value: checked, name: 'considerCompany'})}
            />
        </Table.Cell>
        <Table.Cell textAlign='center'>
            <Checkbox
                style={{cursor: 'pointer'}}
                checked={row['copy']}
                onChange={(e, {checked}) => handleChange(e, {value: checked, name: 'copy'})}
            />
        </Table.Cell>
        {
            actions(row, true)
        }
    </Table.Row>
};

export default EditRow;
