import React, {useContext, useEffect, useRef, useState} from 'react';
import Dropzone from "react-dropzone";
import Tables from "../../components/bigTable/tables";
import {Dimmer, Loader} from "semantic-ui-react";
import Buttons from "./Buttons";
import {tableTabs} from "../../components/bigTable/const";
import WbsImportForm from "../waybills/wbGrid/wbGridToolbar/WbsImportForm";
import {ContextUser} from "../../services/context";
import UserPermissions from "../../api/model/UserPermissions";
import {EXCEL_FORMATS} from "../../api/globalConstants";

const DropzoneFile = ({onDrop, disabled}) => {
    return <div className={`dropzone-block ${disabled ? 'dropzone-block_disabled' : ''}`}>
        <Dropzone
            disabled={disabled}
            onDrop={onDrop}
            multiple={false}
            accept={EXCEL_FORMATS}
            className="dropzone"
        >
            Перетащите файл с заявками или кликните на это поле
        </Dropzone>
    </div>;
};

const FileBlock = ({
                       height = 0,
                       disabled,
                       isValid,
                       processFile,
                       saveFile,
                       tablesData,
                       saveValue,
                       isLoading,
                       isFileExists,
                       defaultActiveIndex,
                       onlyTableMode,
                       loadWaybills,
                       goToWaybillGrid,
                       initData,
                       errors
                   }) => {
    const contextUser = useContext(ContextUser);
    const permissions = contextUser.current.permissions;
    const isOnlyView = !permissions.includes(UserPermissions.IMPORT_FORMS_AND_RULES_MANAGE);

    const [width, setWidth] = useState(0);
    const mainRef = useRef();

    const getMainHeight = () => (height < 100 ? 100 : height) - (onlyTableMode ? 34 : 5);
    const getMainWidth = () => mainRef.current && mainRef.current.getBoundingClientRect().width;

    const setWidthSize = () => setWidth(getMainWidth());

    useEffect(() => {
        setWidthSize();
    }, [mainRef.current]);

    useEffect(() => {
        window.addEventListener('resize', setWidthSize);
        return () => window.removeEventListener('resize', setWidthSize);
    }, []);

    const onDrop = (fileData) => {
        saveFile(fileData);
    };

    const uploadWaybills = (btn) => {
        return <WbsImportForm
            func={loadWaybills}
            refresh={goToWaybillGrid}
            withoutTabs
        >
            {btn}
        </WbsImportForm>;
    };

    const buttons = ({active, openErrors}) => {
        return <Buttons
            initData={initData}
            disabled={disabled}
            onDrop={!onlyTableMode && onDrop}
            isValid={isValid}
            processFile={processFile}
            uploadWaybills={onlyTableMode && uploadWaybills}
            uploadWaybillsDisabled={!onlyTableMode || !tablesData.find(t => t.key === tableTabs[2].key)}
            addPoint={active === tableTabs[1].key && onlyTableMode}
            openErrors={(active === tableTabs[1].key && !!(errors || []).length) && openErrors}
        />;
    };

    return (
        <div>
            <div
                ref={mainRef}
                style={{
                    height: `${getMainHeight()}px`
                }}
                className="file-block__main"
            >
                {
                    isFileExists
                        ? <div>
                            <Dimmer active={isLoading} inverted>
                                <Loader/>
                            </Dimmer>
                            <Tables
                                defaultActiveIndex={defaultActiveIndex}
                                disabled={disabled}
                                buttons={buttons}
                                tables={tablesData}
                                height={getMainHeight()}
                                width={width}
                                saveValue={saveValue}
                                errors={errors}
                            />
                        </div>
                        : <DropzoneFile
                            disabled={disabled}
                            onDrop={saveFile}
                        />
                }
            </div>
        </div>
    );
};

export default FileBlock;