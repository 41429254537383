import O from "../../components/Option";
import {PointTypeEnum} from "./Point";


export function getText(value) {
    const option = this.find(o => o.value === value);
    return option ? option.text : value;
};

export const WeekDayOptions = [
    new O(1, "понедельник"),
    new O(2, "вторник"),
    new O(3, "среда"),
    new O(4, "четверг"),
    new O(5, "пятница"),
	new O(6, "суббота"),
	new O(0, "воскресенье"),
];

export const WeekDayOptions2 = [
    new O(0, "понедельник"),
    new O(1, "вторник"),
    new O(2, "среда"),
    new O(3, "четверг"),
    new O(4, "пятница"),
	new O(5, "суббота"),
	new O(6, "воскресенье"),
];
WeekDayOptions.getText = getText;

export const IncludeOptions = [
    new O(true, 'Учитывать'),
    new O(false, 'Не учитывать')
];

export const CutoffDayOptions = [
    new O(0, "D0"),
    new O(1, "D1"),
    new O(2, "D2"),
    new O(3, "D3"),
    new O(4, "D4"),
    new O(5, "D5"),
];

CutoffDayOptions.getText = getText;

export const TypeAssemblyOptions = [
    new O(1, "Навалом"),
    new O(2, "Опаллечено")
];

export const CargoTypeOptions = [
    new O(10, "alco"),
    new O(20, "cosm"),
    new O(30, "food"),
    new O(40, "nonf"),
    new O(50, "moda"),
    new O(60, "beer"),
    new O(70, "frsh"),
    new O(90, "dang"),
    new O(80, "medicl"),
    new O(100, "text"),
    new O(110, "shos"),
];
CargoTypeOptions.getText = getText;

export const ThermoModeOptions = [
    new O(10, "тент"),
    new O(20, "изотерма"),
    new O(30, "Реф (t+16+18)"),
    new O(40, "Реф (t+5+25)"),
    new O(50, "Реф (t+5+8)"),
    new O(60, "R20C (свободный реф)"),
];

export const RequestTypeEnum = {
    STOCK: 10,
    STOCKACTION: 11,
    TRANSIT: 20,
    TRANSITACTION: 21,
    WHLSALE: 30,
    WHLSALEACTION: 31,
    RETAIL: 40,
    RETAILACTION: 41,
    ACTION: 80
};

export const RequestTypeOptions = [
    new O(RequestTypeEnum.STOCK, "СТОК"),
    new O(RequestTypeEnum.STOCKACTION, "СТОК АКЦИЯ"),
    new O(RequestTypeEnum.TRANSIT, "ТРАНЗИТ"),
    new O(RequestTypeEnum.TRANSITACTION, "ТРАНЗИТ АКЦИЯ"),
    new O(RequestTypeEnum.WHLSALE, "ОПТ"),
    new O(RequestTypeEnum.WHLSALEACTION, "ОПТ АКЦИЯ"),
    new O(RequestTypeEnum.RETAIL, "РОЗНИЦА"),
    new O(RequestTypeEnum.RETAILACTION, "РОЗНИЦА АКЦИЯ"),
    new O(RequestTypeEnum.ACTION, "АКЦИЯ")
];

export const PackTypeEnum = {
    BT: "BT",
    BX: "BX",
    EP: "EP",
    LP: "LP",
    OT: "OT",
    PP: "PP",
    HP: "HP",
    LB: "LB"
};

export const PackTypeOptions = [
	new O(PackTypeEnum.EP, "паллеты"),
	new O(PackTypeEnum.BX, "короба"),
    new O(PackTypeEnum.LP, "низкий паллет"),    // опция на уровне клиента
    new O(PackTypeEnum.OT, "негабаритный паллет"),
    new O(PackTypeEnum.PP, "пустые поддоны"),
    new O(PackTypeEnum.HP, "высокий паллет"),
    new O(PackTypeEnum.LB, "длинный короб"),
    new O(PackTypeEnum.BT, "бутылки"),    // только для возвратов
];

export const PackTypeDisplayOptions = [
    ...PackTypeOptions,
    new O("LP", "низкий паллет")
];

export const WaybillPointTypeEnum = {
    LOADING: 0,
    UNLOADING: 1
};

export const PickupTypeEnum =
{
    NOT_SPECIFIED: 0,
    // Пикап FM
    FM: 1,
    // Другая транспортная компания
    OTHER: 5,
    // Уже на кроссдоке
    DELIVERED: 9
};

export const PickupTypeOptions = [
    new O(1, 'Транспорт FM'),
    new O(5, 'Транспорт другой ТК'),
    new O(9, 'Груз уже на кросс-доке'),
];

export const WaybillFieldEnum = {
    ShippingType: 1,
    ShippingTempCondition: 2,
    CargoType: 3,
    CargoUnitType: 4,
    VehicleCapacity: 5,
    CarType: 6,
    Provider: 7,
    BodyCapacity: 8,
    WaybillStepType: 9,
    StickerShippingType: 10,
    WaybillStepTypeOApi: 11,
};

export const WaybillFieldOptions = [
    new O(WaybillFieldEnum.ShippingType, 'Тип сервиса доставки'),
    new O(WaybillFieldEnum.ShippingTempCondition, 'Температурный режим'),
    new O(WaybillFieldEnum.CargoType, 'Тип груза'),
    new O(WaybillFieldEnum.CargoUnitType, 'Тип грузовых единиц'),
    new O(WaybillFieldEnum.VehicleCapacity, 'Грузоподъемность (FTL)'),
    new O(WaybillFieldEnum.CarType, 'Тип ТС (Rail)'),
    new O(WaybillFieldEnum.BodyCapacity, 'Объем кузова'),
    new O(WaybillFieldEnum.Provider, 'Поставщик'),
    new O(WaybillFieldEnum.WaybillStepType, 'Тип плеча для передачи данных о водителе и ТС в Pooling'),
    new O(WaybillFieldEnum.StickerShippingType, 'Тип сервиса доставки для формирования ПЭ'),
    new O(WaybillFieldEnum.WaybillStepTypeOApi, 'Тип плеча для передачи данных о водителе и ТС в OApi'),
];

export const CompanyTypeOptions = [
    new O(PointTypeEnum.RECEPIENT, "Контрагент"),
    new O(PointTypeEnum.CLIENT_WAREHOUSE, "Склад клиента"),
    new O(PointTypeEnum.FM, "Кросс-док"),
];

CompanyTypeOptions.getText = getText;

export const WaybillCutoffShipmentRegionsEnum = {
	MM: 0,
	MR: 1,
	RM: 2,
	RR: 3
};
export const WaybillCutoffShipmentRegionsEnumOptions = [
    new O(WaybillCutoffShipmentRegionsEnum.MM, "MM"),
    new O(WaybillCutoffShipmentRegionsEnum.MR, "MR"),
    new O(WaybillCutoffShipmentRegionsEnum.RM, "RM"),
    new O(WaybillCutoffShipmentRegionsEnum.RR, "RR")
];
WaybillCutoffShipmentRegionsEnumOptions.getText = getText;

export const recipientTypesEnum = {
    EMAIL: 1,
    USER_EMAIL: 2,
    POOLING: 3,
    ROLE: 4
};

export const AuctionTypeEnum = {
    AD: 10,
    AU: 20,
    FIX: 30,
};

export const AuctionTypeOptions = [
    new O(AuctionTypeEnum.AD, "Понижение"),
    new O(AuctionTypeEnum.AU, "Любая ставка"),
    new O(AuctionTypeEnum.FIX, "Фиксированная ставка"),
];

